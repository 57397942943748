/* eslint-disable react/destructuring-assignment */
import { useQueryLoader, usePreloadedQuery } from 'react-relay';
import {
 Suspense, useEffect, useState, startTransition,
} from 'react';
import miniQL from './miniQLv4';

export default miniQL;

function LoadedHoc({
  Component, queryRef, query, loadQuery, ...props
}) {
  const data = usePreloadedQuery(query, queryRef);

  return (
    <Component
      {...(props || {})}
      {...{
        data: {
          ...data,
          ...(props?.data || {}),
        },
        loadQuery,
      }}
    />
  );
}

/*
  initOnly : dont run at the first time
*/
const loaderHoc = ({
  Component, query, providerVariables, delay, initOnly, fetchPolicy,
}) => function (props) {
  const [queryRef, loadQuery] = useQueryLoader(query);
  const [isInit, setInit] = useState(false);

  useEffect(() => {
    const variables = providerVariables?.(props) || {};
    if (!initOnly) {
      startTransition(() => {
        loadQuery(variables, { fetchPolicy: fetchPolicy || 'network-only' });
      });
    }
    setInit(true);
  }, []);

  if (!isInit) {
    return null;
  }

  return (
    <Suspense>
      {!queryRef ? (!initOnly || <><Component {...props} loadQuery={loadQuery} /></>) : <LoadedHoc
        {...props}
        {...{
 Component, queryRef, query, loadQuery,
}}
      />}
    </Suspense>
  );
};

// export default loaderHoc;
