/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { ridersConfigRider_configRiders$ref } from "./ridersConfigRider_configRiders.graphql";
export type ridersQueryVariables = {||};
export type ridersQueryResponse = {|
  +$fragmentRefs: ridersConfigRider_configRiders$ref
|};
export type ridersQuery = {|
  variables: ridersQueryVariables,
  response: ridersQueryResponse,
|};
*/


/*
query ridersQuery {
  ...ridersConfigRider_configRiders
}

fragment ridersConfigRider_configRiders on Query {
  configRiders: getRiders {
    slug
    defaultValue
    datas {
      min
      max
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ridersQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ridersConfigRider_configRiders"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ridersQuery",
    "selections": [
      {
        "alias": "configRiders",
        "args": null,
        "concreteType": "Rider",
        "kind": "LinkedField",
        "name": "getRiders",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultValue",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RiderData",
            "kind": "LinkedField",
            "name": "datas",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "min",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "max",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1c0a01ebf37af0df763e7793ce4a18a1",
    "id": null,
    "metadata": {},
    "name": "ridersQuery",
    "operationKind": "query",
    "text": "query ridersQuery {\n  ...ridersConfigRider_configRiders\n}\n\nfragment ridersConfigRider_configRiders on Query {\n  configRiders: getRiders {\n    slug\n    defaultValue\n    datas {\n      min\n      max\n    }\n    id\n  }\n}\n"
  }
};
// prettier-ignore
(node/*: any*/).hash = '972e97491951e6d465b9bb3eb5f4af5a';

module.exports = node;
