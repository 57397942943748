import React, {
  useState, useEffect, useReducer, forwardRef, useImperativeHandle,
 } from 'react';
 import { graphql, useFragment } from 'react-relay';
 import { Range, getTrackBackground } from 'react-range';
 import { nanoid } from 'nanoid';
 import { useTranslation } from '@/helpers/translate';
 import Icon from '@/core/icon.js';
 import miniQueryLoader from '@/helpers/miniQueryLoader';

 // Composant de chargement
 function Loader() {
   return <div className="h-[66px] lg:h-fit" />;
 }

 const fragmentConfigRider = graphql`
   fragment ridersConfigRider_configRiders on Query {
     configRiders: getRiders {
       slug
       defaultValue
       datas {
         min
         max
       }
     }
   }
 `;

 const query = graphql`
   query ridersQuery {
     ...ridersConfigRider_configRiders
   }
 `;

 function Rider({ data, fwdRef }) {
   const [loading, setLoading] = useState(true);
   const [configRiders, setconfigRiders] = useState([]);
   const { t } = useTranslation();
   const [configRider, setNewCategory] = useReducer(
     (state, newCate) => configRiders?.find((c) => c.slug === (newCate || 'meeting')),
     configRiders?.find((c) => c.slug === 'meeting'),
   );
   const [openContent, setOpenContent] = useState(false);
   const [openedOnce, setOpenedOnce] = useState(false); // Nouvel état pour suivre si openContent a été ouvert au moins une fois
   const [rider, setRider] = useState({ value: configRider?.defaultValue });

   function getValue() {
     return rider;
   }

   useEffect(() => {
     setRider({ value: configRider?.defaultValue });
   }, [configRider]);

   function setCategory(cate) {
     setNewCategory(cate);
   }

   useImperativeHandle(fwdRef, () => ({
     setCategory,
     getValue,
     setconfigRiders,
   }), [rider, configRiders, configRider]);

   useEffect(() => {
     if (configRiders && !configRider) {
       setNewCategory('meeting');
     }
     setLoading(false);
   }, [configRiders]);

   const handleOpenContent = () => {
     setOpenContent(true);
     setOpenedOnce(true);
   };

   useEffect(() => {
     function handleClickOutside(event) {
       if (!event.target.closest('.riders-container')) {
         setOpenContent(false);
       }
     }

     document.addEventListener('mousedown', handleClickOutside);
     return () => {
       document.removeEventListener('mousedown', handleClickOutside);
     };
   }, []);

   if (loading) {
     return <Loader />;
   }

   if (!configRiders?.length) return null;

   const configRiderData = configRider?.datas[0] || {};
   const { min, max } = configRiderData || {};

   const isMaxValue = rider?.value === max;

   const renderTitle = () => (
     <div className="text-left block text-[14px] text-black pt-2 lg:pt-3 pb-0 px-4" onClick={handleOpenContent}>
       {t('core:riders')}
     </div>
   );

   const renderContent = () => !openContent || (
     <>
       <div className="relative -top-[62px] lg:right-0 lg:top-2 riders-container">
         <div className="w-full lg:min-w-[390px] rounded-lg bg-white absolute z-40 px-1 lg:px-4 py-[13px] lg:py-5 shadow-button">
           <div className="pt-0">
             <div className="w-full transition flex h-full flex-col md:min-w-[350px] pb-0">
               <div className="flex items-center w-full pl-1 pr-3 lg:pr-1">
                 <div className="flex-grow ml-2">
                   <Range
                     values={[rider?.value || min]}
                     step={1}
                     min={min}
                     max={max}
                     onChange={(values) => setRider({ value: values[0] })}
                     renderTrack={({ props, children }) => (
                       <div
                         className="px-2 lg:pl-2 lg:pr-4 lg:mx-0"
                         onMouseDown={props.onMouseDown}
                         onTouchStart={props.onTouchStart}
                         style={{
                           ...props.style,
                           height: '36px',
                           display: 'flex',
                           width: '100%',
                         }}
                       >
                         <div
                           ref={props.ref}
                           style={{
                             height: '6px',
                             width: '100%',
                             borderRadius: '4px',
                             background: getTrackBackground({
                               values: [rider?.value || 1],
                               colors: ['#2974FF', '#d9d9e0'],
                               min,
                               max,
                             }),
                             alignSelf: 'center',
                           }}
                         >
                           {children}
                         </div>
                       </div>
                     )}
                     renderThumb={({ props }) => (
                       <div className="flex items-center justify-center w-8 h-8 bg-white border rounded-full shadow-button border-white-dark3" {...props}>
                         <Icon name="lines" className="h-3 mx-1 fill-current text-blue" />
                       </div>
                     )}
                   />
                 </div>
                 <input
                   type="text"
                   className="text-center rounded-lg min-w-[40px] bg-transparent py-1 px-0 bg-white-dark2 placeholder-dark md:text-base text-black block w-full border-none focus:ring-0"
                   style={{ width: '50px' }}
                   aria-label="participants"
                   value={isMaxValue ? `${rider?.value}+` : rider?.value || min}
                   min={min}
                   max={max}
                   onChange={(event) => {
                     const value = parseInt(event.target.value, 10);
                     if (value >= min && value <= max) {
                       setRider({ value });
                     }
                   }}
                 />
               </div>
             </div>
           </div>
         </div>
       </div>
     </>
   );

   const renderResume = () => {
     if (!openedOnce || !rider?.value) {
       return <div className="text-left md:text-lg text-dark" onClick={handleOpenContent}>{t('core:how')}</div>;
     }

     return (
       <div className="text-left text-black md:text-lg" onClick={handleOpenContent}>
         {rider?.value}
       </div>
     );
   };

   if (configRider?.slug === 'production') return null;

   return (
     <div className="mb-2 border rounded-lg min-w-44 lg:relative border-white-dark4 shadow-light lg:border-none lg:shadow-none lg:rounded-none lg:mb-0 lg:border-b-0">
       {renderTitle()}
       <div className="min-w-40 text-left bg-transparent lg:relative lg:bottom-[1px] lg:h-12 pt-0 pb-2 lg:pt-1 lg:pb-3 px-4 md:text-md block w-full border-none focus:ring-0" onClick={() => setOpenContent(true)}>
         {renderResume()}
       </div>
       {renderContent()}
     </div>
   );
 }

 const MiniQueryLoaderRiders = miniQueryLoader({
   Component: ({ data, setconfigRiders }) => {
     const dataFrag = useFragment(fragmentConfigRider, data);
     useEffect(() => {
       setconfigRiders(dataFrag?.configRiders);
     }, [dataFrag]);
     return null;
   },
   query,
   fetchPolicy: 'store-or-network',
 });

 function ListenerRiders({ setconfigRiders }) {
   const [loaded, setIsLoaded] = useState(false);

   return loaded || <MiniQueryLoaderRiders
     key={nanoid()}
     setconfigRiders={(value) => {
     setconfigRiders(value);
     setIsLoaded(true);
   }}
   />;
 }

 export default forwardRef((props, ref) => (
   <>
     <div>
       <Rider fwdRef={ref} />
     </div>
     <ListenerRiders setconfigRiders={(value) => {
       ref?.current?.setconfigRiders(value);
     }}
     />
   </>
 ));
