/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ridersConfigRider_configRiders$ref: FragmentReference;
declare export opaque type ridersConfigRider_configRiders$fragmentType: ridersConfigRider_configRiders$ref;
export type ridersConfigRider_configRiders = {|
  +configRiders: ?$ReadOnlyArray<?{|
    +slug: ?string,
    +defaultValue: ?number,
    +datas: ?$ReadOnlyArray<?{|
      +min: ?number,
      +max: ?number,
    |}>,
  |}>,
  +$refType: ridersConfigRider_configRiders$ref,
|};
export type ridersConfigRider_configRiders$data = ridersConfigRider_configRiders;
export type ridersConfigRider_configRiders$key = {
  +$data?: ridersConfigRider_configRiders$data,
  +$fragmentRefs: ridersConfigRider_configRiders$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ridersConfigRider_configRiders",
  "selections": [
    {
      "alias": "configRiders",
      "args": null,
      "concreteType": "Rider",
      "kind": "LinkedField",
      "name": "getRiders",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultValue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RiderData",
          "kind": "LinkedField",
          "name": "datas",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "min",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "max",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '1284a5421206eb8d5c3a28b2a30d1e6e';

module.exports = node;
